  @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

  $linkHoverBgColor: rgba(191, 161, 107, 0.4);
  $linkHoverColor: rgba(50, 50, 50, 1);

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    font-weight: 400;


    h1 {
      color: rgb(70, 70, 70);
      font-weight: 400;
    }

    h2 {
      color: rgb(80, 80, 80);
      font-weight: 400;
    }

    h3 {
      color: rgb(50, 50, 50);
      font-weight: 400;
    }

    h4 {
      color: rgb(90, 90, 90);
      font-weight: 400;
    }

    p {
      color: rgb(100, 100, 100);
      font-size: 1.1rem;
    }

    a {
      color: rgb(100, 100, 100);
      transition: all 150ms ease-in-out;
    }

    a:hover {
      color: $linkHoverColor;
      background-color: $linkHoverBgColor;
    }

    .icon {
      color: rgb(50, 50, 50);
    }
  }

  .App {
    //display: flex;
    flex-direction: column;
    min-width: 380px;

    header {
      background-color: transparent;
      //display: none;
    }

    main {
      background-color: transparent;
      //display: none;
    }

    footer {
      background-color: rgba(119, 119, 119, 1);
      background-color: transparent;
      //display: none;
    }
  }

  @import './navigation.css';
  @import './display.css';
  @import './contacts.css';
  @import './info.css';
  @import './copyright.css';
  @import './review-slider.css';
  @import './product-slider.css';
  @import './section-devider.css';
  @import './message-success-error.css';

  @import './reszletek.css';

  @import './kezdolap.css';
  @import './servs_and_prod.css';
  @import './rolam.css';
  @import './kapcsolat.css';
  @import './gyik.css';

  // Webshop imports
  @import './webshop.css';